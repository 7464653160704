import { groupBy, isEmpty, map, sumBy } from 'lodash';

import type { EmissionDetail, GetEmissionQuery } from '../../services/graphql/generated';
import { EmissionDetailSubtypeEnum, EmissionDetailTypeEnum } from '../../services/graphql/generated';

import type { LifecycleTypeBreakdown } from './types';
import { EmissionDetailTypeGroupEnum } from './types';

type Emission = GetEmissionQuery['emission'];
type EmissionDetails = Emission['details'][number];

// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
interface AggregatedCO2e<key extends keyof EmissionDetail = keyof EmissionDetail> {
  type: EmissionDetail[key];
  co2e: number;
}

export const ELECTRICITY_BASED_SUBTYPES = [
  EmissionDetailSubtypeEnum.Eg,
  EmissionDetailSubtypeEnum.Etdl,
  EmissionDetailSubtypeEnum.Production,
];

export const getEmissionDetails = (emission: {
  emission_children: { details: GetEmissionQuery['emission']['details'] }[];
}): GetEmissionQuery['emission']['details'] =>
  emission.emission_children.flatMap((childEmission) => childEmission.details);

export const aggregateDetailsToEnum = <U extends object>(
  emissionDetails: EmissionDetails[],
  key: keyof EmissionDetail,
  toEnum: U,
) => {
  const aggValues: AggregatedCO2e<typeof key>[] = [];

  Object.keys(toEnum).forEach((type) => {
    const agg = emissionDetails.reduce((sum, child) => {
      // @ts-expect-error -- enabling strict mode
      if (child[key] === toEnum[type]) {
        return sum + (child.co2e || 0);
      }
      return sum;
    }, 0);

    // @ts-expect-error -- enabling strict mode
    aggValues.push({ type: toEnum[type], co2e: agg });
  });

  return aggValues.filter((value) => value?.co2e > 0);
};

const lcaCompare = (a: LifecycleTypeBreakdown, b: LifecycleTypeBreakdown) => {
  const typeOrder: LifecycleTypeBreakdown['type'][] = [
    EmissionDetailTypeEnum.Wtt,
    EmissionDetailTypeEnum.Ttw,
    EmissionDetailTypeEnum.Other,
    EmissionDetailTypeEnum.Wtw,
  ];

  const aIndex = typeOrder.indexOf(a.type);
  const bIndex = typeOrder.indexOf(b.type);

  if (aIndex !== bIndex) {
    return aIndex - bIndex;
  }

  return a.value - b.value;
};

export const getLcaBreakdownFromEmissionDetail = (details: EmissionDetails[]): LifecycleTypeBreakdown[] =>
  map(groupBy(details, 'type'), (value, key) => {
    const aggregatedSubtypes: LifecycleTypeBreakdown[] = Object.entries(
      groupBy(value, (v) => {
        if (!v.subtype) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- eslint upgrade
          return key === EmissionDetailTypeEnum.Other
            ? EmissionDetailTypeGroupEnum.OTHER
            : EmissionDetailTypeGroupEnum.FUEL_BASED;
        }

        return ELECTRICITY_BASED_SUBTYPES.includes(v.subtype)
          ? EmissionDetailTypeGroupEnum.ELECTRICITY_BASED
          : EmissionDetailTypeGroupEnum.FUEL_BASED;
      }),
    ).map(([subKey, subValue]) => {
      const aggregatedFuelBasedCo2e = sumBy(subValue, 'co2e');
      const groupBreakdown: LifecycleTypeBreakdown = {
        type: subKey as EmissionDetailTypeGroupEnum,
        value: aggregatedFuelBasedCo2e,
        unit: 'kg',
        key: subKey,
      };

      if (subValue.length > 1) {
        const breakdown: LifecycleTypeBreakdown[] = subValue
          .filter((detail) => !!detail.subtype)
          .map((detail) => ({
            type: detail.subtype!,
            value: detail.co2e || 0,
            unit: 'kg',
          }));

        if (!isEmpty(breakdown)) {
          groupBreakdown.breakdown = breakdown;
        }
      }

      return groupBreakdown;
    });

    return {
      type: key as EmissionDetailTypeEnum,
      value: sumBy(value, 'co2e'),
      unit: 'kg',
      breakdown: aggregatedSubtypes,
    };
  }).sort(lcaCompare);
